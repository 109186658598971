import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import Landing from './Pages/Landing.js';
import Trail from './Pages/Trail.js';
import Admin from './Pages/Admin.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Route exact path="/" component={Landing}/>
          <Route path="/trail" render={(props) => <Trail textMode={false} {...props} />}/>
          <Route path="/text-trail" render={(props) => <Trail textMode={true} {...props} />}/>
          <Route path="/admin" component={Admin}/>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
