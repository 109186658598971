import React, { Component } from 'react';
import Title from '../Assets/breadcrumbs-title.png';
import SmallCrumb1 from '../Assets/Crumbs/small-crumb1.png';
import SmallCrumb2 from '../Assets/Crumbs/small-crumb2.png';
import SmallCrumb3 from '../Assets/Crumbs/small-crumb3.png';
import MediumCrumb1 from '../Assets/Crumbs/medium-crumb1.png';
import MediumCrumb2 from '../Assets/Crumbs/medium-crumb2.png';
import LargeCrumb1 from '../Assets/Crumbs/large-crumb1.png';
import BlueBird from '../Assets/Birds/blue-bird.png';
import GreenBird from '../Assets/Birds/green-bird.png';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faDeaf } from '@fortawesome/free-solid-svg-icons';
import '../Styles/Landing.css';

const keyboardUserCssClass = "keyboardUser"

class Landing extends Component {

  componentDidMount() {
    document.body.style.backgroundColor = "#F9E0AC"

    /* Show blue outlines to interactive elements only if user is using
    keyboard accessibility mode */
    document.addEventListener("keydown", e => {
      if (e.key === "Tab") {
       this.setIsKeyboardUser(true);
      }
    });
    document.addEventListener("click", e => {
      this.setIsKeyboardUser(!e.screenX && !e.screenY);
    });
    
    document.addEventListener("mousedown", e => {
      this.setIsKeyboardUser(false);
    });
  }

  setIsKeyboardUser(isKeyboard) {
    const { body } = document;
    if (isKeyboard) {
     body.classList.contains(keyboardUserCssClass) || body.classList.add(keyboardUserCssClass);
    } else {
     body.classList.remove(keyboardUserCssClass);
    }
  }
  
  render() {
    if (!isMobile) {
      return (
        <div>
          <img src={Title} className="title-text" alt=""/>
          <img src={MediumCrumb1} className="medium-breadcrumb" style={{top: '18vh', left: '75vw'}} alt=""/>
          <img src={SmallCrumb1} className="small-breadcrumb" style={{top: '24vh', left: '65vw'}} alt=""/>
          <img src={SmallCrumb2} className="small-breadcrumb" style={{top: '30vh', left: '50vw'}} alt=""/>
          <img src={MediumCrumb2} className="medium-breadcrumb" style={{top: '29vh', left: '33vw'}} alt=""/>
          <img src={SmallCrumb1} className="small-breadcrumb" style={{top: '38vh', left: '23vw'}} alt=""/>
          <img src={SmallCrumb3} className="small-breadcrumb" style={{top: '44vh', left: '12vw'}} alt=""/>
          <img src={SmallCrumb2} className="small-breadcrumb" style={{top: '53vh', left: '27vw'}} alt=""/>
          <img src={SmallCrumb1} className="small-breadcrumb" style={{top: '56vh', left: '42vw'}} alt=""/>
          <img src={GreenBird} className="landing-bird" style={{top: '48vh', left: '46vw'}} alt=""/>
          <a href="/trail" onKeyPress={() => window.location.href="/trail"}>
            <img src={LargeCrumb1} 
              className="large-interactive-breadcrumb"
              tabIndex="1"
              style={{top: '58vh', left: '66vw'}} 
              alt="Enter the interactive breadcrumb trail"
            />
          </a>
          <h1 className="landing-text">follow the breadcrumbs...</h1>

          <Button tabIndex="2" variant="flat" style={{position: 'absolute', top: '87vh', left: '0.5vw', backgroundColor: '#B6BDBE', borderRadius: '20px', width: '140px'}} onClick={() => window.location.href="/text-trail"}>
            <FontAwesomeIcon icon={faDeaf} size="1x" color="white"/>
            <p className="button-text">click here for a readable experience</p>
          </Button>
        </div>
      )
    } else {
      return (
        <div>
          <img src={Title} className="mobile-title-text" alt=""/>
          <img src={BlueBird} className="mobile-bird" alt=""/>
          <h1 className="mobile-landing-text">
            This experience is not designed for mobile users. Please visit this site on a laptop or desktop device
          </h1>
        </div>
      )
    }
  }
}

export default Landing;