import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import '../Styles/Admin.css';

// let api_url = "http://0.0.0.0:3001/"
let api_url = "https://api.breadcrumbsproject.com/"

let questions = [
  "A memory of the best smell from the kitchen", 
  "A memory of the person who fed you the most growing up", 
  "A memory of a food-related tradition", 
  "A memory of a meal you wish you could eat again", 
  "A memory of dinner table rules",
  "A memory of the person who taught you to cook",
  "A memory of something hard to swallow",
  "A memory that belongs in this project" ]

class Admin extends Component {

  state = {
    pending_responses: [],
    approved_responses: [],
    featured_responses: []
  }

  async componentDidMount() {
    // this.responses()
    // this.interval = setInterval(this.getNewResponses, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getNewResponses = async() => {
    let pending_responses = await axios.get(api_url + "responses-pending")
    pending_responses = pending_responses.data
    this.setState({ pending_responses })
  }

  async responses() {
    let pending_responses = await axios.get(api_url + "responses-pending")
    pending_responses = pending_responses.data
    this.setState({ pending_responses })

    let approved_responses = await axios.get(api_url + "responses-approved")
    approved_responses = approved_responses.data
    this.setState({ approved_responses })

    let featured_responses = await axios.get(api_url + "responses-featured")
    featured_responses = featured_responses.data
    this.setState({ featured_responses })
  }

  async setApproval(id, approval) {
    await axios.get(api_url + "approval-set", {
      params: {
        id: id,
        approval: approval
      }
    })
    this.responses()
  }

  async featureResponse(id) {
    let featured_responses = await axios.get(api_url + "responses-featured")
    let new_rank = featured_responses.data.length + 1
    axios.get(api_url + "rank-set", {
      params: {
        id: id,
        rank: new_rank
      }
    })
    this.responses()
  }

  async unfeatureResponse(item) {
    await axios.get(api_url + "rank-set", {
      params: {
        id: item.id,
        rank: -1
      }
    })
    for (var i = item.rank; i < this.state.featured_responses.length; i++) {
      await axios.get(api_url + "rank-set", {
      params: {
        id: this.state.featured_responses[i].id,
        rank: i
      }
    })
    }
    this.responses()
  }

  pendingItem(item) {
    return (
      <div>
        <p className="title">Question: {questions[item.question - 1]}</p>
        <p className="body">Response: {item.response}</p>
        <Button onClick={() => this.setApproval(item.id, "true")}>
          Approve
        </Button>
        <Button onClick={() => this.setApproval(item.id, "false")}>
          Deny
        </Button>
      </div>
    );
  }

  approvedItem(item) {
    return (
      <div>
        <p className="title">Question: {questions[item.question - 1]}</p>
        <p className="body">Response: {item.response}</p>
        <Button onClick={() => this.featureResponse(item.id)}>
          Feature
        </Button>
      </div>
    );
  }

  featuredItem(item) {
    return (
      <div>
        <p className="title">Question: {questions[item.question - 1]}</p>
        <p className="body">Response: {item.response}</p>
        <Button onClick={() => this.unfeatureResponse(item)}>
          Unfeature
        </Button>
      </div>
    );
  }

  handleChangedRanks(reorderedItems) {
    let old_responses = this.state.featured_responses
    let new_responses = reorderedItems

    var first_id = 0
    var first_rank = 0
    var second_id = 0
    var second_rank = 0

    for (var i = 0; i < old_responses.length; i++) {
      if (old_responses[i].id !== new_responses[i].id) {
        first_id = old_responses[i].id
        first_rank = old_responses[i].rank
        second_id = new_responses[i].id
        second_rank = new_responses[i].rank
        break;
      }
    }

    axios.get(api_url + "rank-set", {
      params: {
        id: first_id,
        rank: second_rank
      }
    })

    axios.get(api_url + "rank-set", {
      params: {
        id: second_id,
        rank: first_rank
      }
    })

    this.responses()
  }

  disableDrag() {
    // Does nothing
  }
  
  render() {

    return (
      <div class="container">
        <div>
          <h1>Pending</h1>
          <RLDD
            items={ this.state.pending_responses }
            itemRenderer={ this.pendingItem.bind(this) }
            onChange={ this.disableDrag.bind(this) }
          />
        </div>
        <div>
          <h1>Approved</h1>
          <RLDD
            items={ this.state.approved_responses }
            itemRenderer={ this.approvedItem.bind(this) }
            onChange={ this.disableDrag.bind(this) }
          />
        </div>
        <div>
          <h1>Featured</h1>
          <p>Drag responses in the order you want them to appear</p>
          <RLDD
            items={ this.state.featured_responses }
            itemRenderer={ this.featuredItem.bind(this) }
            onChange={ this.handleChangedRanks.bind(this) }
          />
        </div>
      </div>
    )
  }
}

export default Admin;